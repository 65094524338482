<template>
	<div
		style="display: inline-block"
		:style="{
			width: tag === 'b-dropdown-item' ? '100%' : ''
		}"
	>
		<span v-if="isDisabled && !inProdMode()">
			<subscription-dialog :variant-btn="variantBtn" :tag="tag" :icon="icon" :btn-label="btnLabel" />
		</span>
		<span v-else>
			<div v-if="!hideButton">
				<b-button v-if="tag === 'is-lucide'" v-b-modal="nameModal" :variant="variantBtn" :disabled="isDisabled">
					{{ btnLabel }}&nbsp;&nbsp;
					<Share color="#06263E" :size="16" />
				</b-button>
				<b-button v-if="tag === 'b-button'" v-b-modal="nameModal" :variant="variantBtn" :disabled="isDisabled">
					<i :class="icon" aria-hidden="true" />&nbsp;&nbsp;{{ btnLabel }}
				</b-button>
				<b-dropdown-item v-if="tag === 'b-dropdown-item'" v-b-modal="nameModal" :disabled="isDisabled">
					<b-icon icon="envelope" />&nbsp;&nbsp;{{ btnLabel }}
				</b-dropdown-item>
			</div>
			<b-modal
				:id="nameModal"
				ref="modal-share-subscription-solo"
				v-model="isOpen"
				:title="FormMSG(236, 'Confirm')"
				ok-variant="primary"
				cancel-variant="outline-primary"
				no-close-on-backdrop
				no-close-on-esc
				hide-header-close
				header-class="header-modal-custom"
				body-class="body-modal-custom"
				footer-class="footer-modal-custom"
				:ok-disabled="disabledSave"
				:cancel-disabled="disabledCancel"
				:cancel-title="FormMSG(4, 'Cancel')"
				@ok.prevent="handleSubmitModal"
			>
				<template #modal-ok>
					<div>
						<b-spinner v-if="disabledSave" small />
						{{ FormMSG(3, 'Send') }}
					</div>
				</template>
				<b-form>
					<b-alert variant="danger" :show="errorMessage !== ''" dismissible v-html="errorMessage" />
					<b-row>
						<b-col>
							<p v-if="currentTimesheet && isSendInvoice" class="font-400">
								{{ FormMSG(64, 'Please select the recipient and confirm the options to send the timesheet invoice.') }}
							</p>
							<p v-if="currentExpense && isSendInvoice" class="font-400">
								{{ FormMSG(65, 'Please select the recipient and confirm the options to send the expense invoice.') }}
							</p>
						</b-col>
					</b-row>
					<div v-if="(currentTimesheet || currentExpense) && isSendInvoice" class="divider"></div>
					<b-form-group id="fieldset-1" :label="titleModal" label-for="input-1" label-class="font-700 mt-3" v-click-outside="handleOutside">
						<v-select
							v-model="form.emails"
							:options="emailsFromCookies"
							taggable
							:multiple="true"
							:placeholder="FormMSG(556, 'Address email...')"
							@search="handleSearch"
							@option:created="onOptionCreated"
							@option:deselecting="onOptionDeselect"
							@option:deselected="onOptionDeselect"
						/>
						<div v-if="error.inputEmail" class="text-danger">
							{{ FormMSG(7, 'Enter a address valid') }}
						</div>
					</b-form-group>
					<b-row class="mb-3">
						<b-col sm="6">
							<div style="line-height: 22px">
								<b-form-checkbox id="checkbox-1" v-model="form.save_address" name="checkbox-1" :value="true" :unchecked-value="false">
									{{ FormMSG(6, 'Save this address') }}
								</b-form-checkbox>
							</div>
						</b-col>
						<b-col v-if="showCheckMemoDeal && $screen.width > 567" sm="6">
							<b-form-checkbox
								id="checkbox-sendMemoDeal"
								v-model="form.sendMemoDeal"
								name="checkbox-sendMemoDeal"
								:value="true"
								:unchecked-value="false"
							>
								{{ FormMSG(42, 'Send memo deal') }}
							</b-form-checkbox>
						</b-col>
					</b-row>
					<b-row v-if="sendACopy" class="mb-3">
						<b-col sm="8">
							<div style="line-height: 22px">
								<b-form-checkbox id="checkbox-2" v-model="form.sendMeACopy" name="checkbox-2" :value="true" :unchecked-value="false">
									{{ FormMSG(10, 'Send me a copy') }}
								</b-form-checkbox>
							</div>
						</b-col>
					</b-row>
					<b-row class="mb-3">
						<b-col sm="8">
							<div style="line-height: 22px">
								<b-form-checkbox id="printPdf" v-model="form.printPdf" name="checkbox-2" :value="true" :unchecked-value="false">
									{{ FormMSG(28, 'Send in PDF format') }}
								</b-form-checkbox>
							</div>
						</b-col>
					</b-row>
					<div v-if="(currentTimesheet || currentExpense) && isSendInvoice">
						<div class="divider"></div>
						<p class="font-700 mt-3">
							{{ FormMSG(67, 'Invoicing options:') }}
						</p>
						<b-row class="mb-2 mt-4">
							<b-col cols="6">
								<p class="font-400">
									{{ FormMSG(68, 'Invoice number:') }}
								</p>
							</b-col>
							<b-col cols="6">
								<b-input-group>
									<b-form-input
										v-model="invoiceNumber"
										type="text"
										v-mask="'########'"
										class="custom-form-control"
										placeholder="0"
										@input="handleInputNumericField($event, 'invoiceNumber')"
									/>
								</b-input-group>
							</b-col>
						</b-row>
						<b-row v-if="currentTimesheet" class="mb-2">
							<b-col cols="6">
								<p class="font-400">
									{{ FormMSG(69, 'VAT:') }}
								</p>
							</b-col>
							<b-col cols="6">
								<b-input-group>
									<b-form-input
										v-model="vat"
										v-mask="'########'"
										type="text"
										class="custom-form-control"
										placeholder="0"
										@input="handleInputNumericField($event, 'vat')"
									/>
									<b-input-group-append>
										<b-input-group-text class="custom-input-group-text">
											<percent :size="16" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-col>
						</b-row>
						<b-row class="mb-2">
							<b-col cols="6">
								<p class="font-400">
									{{ FormMSG(70, 'Discount:') }}
								</p>
							</b-col>
							<b-col cols="6">
								<b-input-group>
									<b-form-input
										v-model="discount"
										v-mask="'########'"
										type="text"
										class="custom-form-control"
										placeholder="0"
										@input="handleInputNumericField($event, 'discount')"
									/>
									<b-input-group-append>
										<b-input-group-text class="custom-input-group-text">
											<percent :size="16" />
										</b-input-group-text>
									</b-input-group-append>
								</b-input-group>
							</b-col>
						</b-row>
					</div>
				</b-form>
			</b-modal>
		</span>
	</div>
</template>

<script>
import ClickOutside from 'vue-click-outside';
import { validationMixin } from 'vuelidate';
import languageMessages from '@/mixins/languageMessages';
import { store } from '@/store';
import axios from 'axios';
import { apolloClient as apollo } from '@/vue-apollo';
import gql from 'graphql-tag';
import isSingleProjectMixin from '@/mixins/isSingleProject.mixin';
import SubscriptionDialog from './SubscriptionDialog';
import { isNil } from '../../shared/utils';
import { Share, Percent } from 'lucide-vue';
import globalMixin from '@/mixins/global.mixin';

export default {
	name: 'ShareInformation',

	directives: {
		ClickOutside
	},

	components: {
		SubscriptionDialog,
		Share,
		Percent
	},

	mixins: [languageMessages, validationMixin, isSingleProjectMixin, globalMixin],

	props: {
		btnLabel: { type: String, required: true },
		titleModal: { type: String, required: true },
		sendACopy: { type: Boolean, default: false, required: false },
		currentTimesheet: { type: Object, default: null, required: false },
		currentExpense: { type: Object, default: null, required: false },
		nameModal: { type: String, default: 'modal-1' },
		isSendInvoice: { type: Boolean, default: false },
		offer: { type: Object, default: null },
		icon: { type: String, default: 'fa fa-share-alt' },
		variantBtn: { type: String, default: 'primary' },
		isDisabled: { type: Boolean, default: false },
		tag: { type: String, default: 'b-button' },
		checkPay: { type: Boolean, default: false },
		hideButton: { type: Boolean, required: false, default: false },
		msgSuccess: { type: String, required: false, default: '' },
		showCheckMemoDeal: { type: Boolean, default: false }
	},

	data() {
		return {
			isOpen: false,
			error: {
				inputEmail: false
			},
			form: {
				emails: [],
				save_address: false,
				sendMeACopy: false,
				sendMemoDeal: false,
				printPdf: false
			},
			emailsFromCookies: [],
			newEmails: [],

			invoiceNumber: 0,
			vat: 0,
			discount: 0,

			emailSearch: null,
			disabledSave: false,
			disabledCancel: false,
			errorMessage: ''
		};
	},

	computed: {
		validateStateEmails() {
			if (this.form.emails.length > 0) {
				return null;
			}
			return true;
		},
		urlStr() {
			if (this.currentTimesheet !== null && this.currentExpense === null) {
				return `${process.env.VUE_APP_GQL}/timesheetReport/${this.currentTimesheet.id}`;
			} else if (this.currentTimesheet === null && this.currentExpense !== null) {
				return `${process.env.VUE_APP_GQL}/expenseReport/${this.currentExpense.id}`;
			}
		},
		myEmail() {
			return store.state.myProfile.email;
		}
	},

	mounted() {
		this.invoiceNumber = store.getCurrentProjectConfig().lastInvoiceNumber;
		this.vat = store.getCurrentProjectConfig().vatPercent;

		this.getCookiesEmailSended();
	},

	methods: {
		handleInputNumericField(payload, field) {
			if (payload === '') {
				this[field] = 0;
			} else {
				const splitPayload = payload.split('');
				if (splitPayload[0] === '0') {
					splitPayload.shift();
					this[field] = splitPayload.join('');
				}
			}
		},
		inProdMode() {
			if (process.env.VUE_APP_RUNNING_MODE == 'PROD') {
				return true;
			}
			return false;
		},
		handleSearch(payload) {
			if (payload.length === 1) {
				this.emailSearch = null;
			}
			this.emailSearch = payload;
			//this.getCookiesEmailSended()
		},
		handleOutside() {
			if (this.ValidateEmail(this.emailSearch)) {
				this.form.emails.push(this.emailSearch);
				this.emailSearch = null;
				this.$nextTick(() => {
					let vsSearch = document.querySelector('.vs__search');
					vsSearch.value = '';
				});
			}
		},
		onOptionCreated(payload) {
			if (this.ValidateEmail(payload)) {
				this.newEmails.push(payload);
				this.error.inputEmail = false;
			} else {
				this.$nextTick(() => {
					this.form.emails = this.form.emails.filter((email) => email !== payload);
					this.error.inputEmail = true;
				});
			}
		},
		onOptionDeselect(payload) {
			this.newEmails = this.newEmails.filter((v) => v !== payload);
		},
		getCookiesEmailSended() {
			if (this.$storage.isKey('i_s_emails_sended')) {
				this.emailsFromCookies = JSON.parse(this.$storage.get('i_s_emails_sended'));
				this.emailsFromCookies = this.uniqueArray(this.emailsFromCookies);
			}
		},
		async handleSubmitModal() {
			this.errorMessage = '';
			this.handleOutside();
			if (this.form.emails.length === 0) {
				return;
			}

			if (this.form.save_address) {
				this.newEmails.forEach((newEmail) => {
					const findIndex = this.emailsFromCookies.findIndex((email) => email === newEmail);
					if (findIndex === -1) {
						this.emailsFromCookies.push(newEmail);
					}
				});

				this.$storage.set('i_s_emails_sended', JSON.stringify(this.emailsFromCookies), '30d');
			}
			if (this.offer !== null) {
				this.actionDisabled(true);
				apollo
					.mutate({
						mutation: gql`
							mutation SEND_TASK_OFFER($taskId: ID!, $emails: [String]!) {
								SendTaskOffer(TaskId: $taskId, Emails: $emails)
							}
						`,
						variables: {
							emails: this.form.emails,
							taskId: parseInt(this.offer.id)
						},
						fetchPolicy: 'no-cache'
					})
					.then((data) => {
						this.actionDisabled(false);
						this.$emit('share-information:sended', this.offer);
						this.successSend();
					})
					.catch((error) => {
						this.errorMessage = error.message;
						this.actionDisabled(false);
						console.error(error);
					});
			} else {
				if (!this.isSendInvoice) {
					if (this.currentTimesheet === null && this.currentExpense === null) {
						this.actionDisabled(true);
						apollo
							.mutate({
								mutation: gql`
									mutation SEND_INFO_SHEET($emails: [String]!, $sendMemoDeal: Boolean, $printPdf: Boolean) {
										SendInfoSheet(Emails: $emails, SendMemoDeal: $sendMemoDeal, PrintPDF: $printPdf)
									}
								`,
								variables: {
									emails: this.form.emails,
									sendMemoDeal: this.form.sendMemoDeal,
									printPdf: this.form.printPdf
								},
								fetchPolicy: 'no-cache'
							})
							.then((data) => {
								this.actionDisabled(false);
								this.successSend();
							})
							.catch((error) => {
								this.errorMessage = error.message;
								this.actionDisabled(false);
								console.error(error);
							});
					} else if (this.currentTimesheet !== null || this.currentExpense !== null) {
						this.actionDisabled(true);
						let config = {
							headers: {
								Authorization: `Bearer ${store.state.token}`
							}
						};
						let listRequestToSend = [];
						for (let i = 0; i < this.form.emails.length; i++) {
							const emailEncoded = encodeURIComponent(this.form.emails[i]);
							const isPdf = this.form.printPdf === true ? 1 : 0;
							listRequestToSend.push(axios.get(this.urlStr + '?email=' + emailEncoded + '&pdf=' + isPdf, config));
						}
						if (this.form.sendMeACopy) {
							const emailEncoded = encodeURIComponent(this.myEmail);
							const isPdf = this.form.printPdf === true ? 1 : 0;
							listRequestToSend.push(axios.get(this.urlStr + '?email=' + emailEncoded + '&pdf=' + isPdf, config));
						}

						Promise.all(listRequestToSend).then((response) => {
							this.actionDisabled(false);
							this.successSend();
						});
					}
				} else {
					this.sendInvoice();
				}
			}
		},
		actionDisabled(value) {
			this.disabledSave = value;
			this.disabledCancel = value;
		},
		async sendInvoice() {
			try {
				this.actionDisabled(true);
				let response = false;

				this.invoiceNumber = this.invoiceNumber === '' ? 0 : parseInt(this.invoiceNumber, 10);
				this.vat = this.vat === '' ? 0 : parseInt(this.vat, 10);
				this.discount = this.discount === '' ? 0 : parseInt(this.discount, 10);

				if (this.form.sendMeACopy) {
					this.form.emails.push(this.myEmail);
				}
				if (this.currentTimesheet !== null && this.currentExpense === null) {
					const mutation = gql`
						mutation SendInvoice(
							$invoiceCode: ID!
							$emails: [String]!
							$invoiceId: ID!
							$invoiceNumber: ID
							$discount: ID
							$vat: ID
							$printPdf: Boolean
						) {
							SendInvoice(
								InvoiceCode: $invoiceCode
								Emails: $emails
								InvoiceId: $invoiceId
								InvoiceNumber: $invoiceNumber
								Discount: $discount
								Vat: $vat
								PrintPDF: $printPdf
							)
						}
					`;
					response = await apollo.mutate({
						mutation,
						variables: {
							invoiceCode: 1,
							invoiceId: parseInt(this.currentTimesheet.id),
							emails: this.form.emails,
							invoiceNumber: this.invoiceNumber,
							discount: this.discount,
							vat: this.vat,
							printPdf: this.form.printPdf
						},
						fetchPolicy: 'no-cache'
					});
				} else if (this.currentTimesheet === null && this.currentExpense !== null) {
					const mutation = gql`
						mutation SendInvoice($invoiceCode: ID!, $emails: [String]!, $invoiceId: ID!, $invoiceNumber: ID, $discount: ID, $printPdf: Boolean) {
							SendInvoice(
								InvoiceCode: $invoiceCode
								Emails: $emails
								InvoiceId: $invoiceId
								InvoiceNumber: $invoiceNumber
								Discount: $discount
								PrintPDF: $printPdf
							)
						}
					`;
					response = await apollo.mutate({
						mutation,
						variables: {
							invoiceCode: 0,
							invoiceId: parseInt(this.currentExpense.id),
							emails: this.form.emails,
							invoiceNumber: this.invoiceNumber,
							discount: this.discount,
							printPdf: this.form.printPdf
						},
						fetchPolicy: 'no-cache'
					});
				}

				if (response) {
					await this.successSend();
					await store.reloadMyProject();

					this.invoiceNumber = store.getCurrentProjectConfig().lastInvoiceNumber;
				}
				this.actionDisabled(false);
			} catch (error) {
				this.errorMessage = error.message;
				this.actionDisabled(false);
				console.log(error.message);
			}
		},
		async successSend() {
			this.form.save_address = false;
			this.newEmails = [];
			this.form.emails = [];
			this.isOpen = false;
			this.emailSearch = null;
			/*

    currentTimesheet: { type: Object, default: null, required: false },
    currentExpense: { type: Object, default: null, required: false },
      isSendInvoice: { type: Boolean, default: false },
    offer: { type: Object, default: null },

    */
			var msg = this.FormMSG(53, 'Send infosheet successfull');
			//console.log("this.currentTimesheet",this.currentTimesheet)
			if (this.msgSuccess !== '') {
				msg = this.msgSuccess;
			} else {
				if (!isNil(this.currentTimesheet)) {
					msg = this.FormMSG(154, 'Timesheet successfully sent');
				} else if (!isNil(this.currentExpense)) {
					msg = this.FormMSG(155, 'Expense sheet successfully sent');
				} else if (!isNil(this.isSendInvoice)) {
					msg = this.FormMSG(156, 'Invoice successfully sent');
				} else if (!isNil(this.offer)) {
					msg = this.FormMSG(157, 'Offer successfully sent');
				}
			}

			if (this.$screen.width <= 567) {
				this.createToastForMobile(msg, '', '', 'success');
			} else {
				this.createToastForMobile(this.FormMSG(54, 'Success'), msg, '', 'success');
			}
		},
		ValidateEmail(mail) {
			if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,10})+$/.test(mail)) {
				return true;
			}

			return false;
		},
		uniqueArray(arr) {
			const response = arr.filter(function (item, pos, self) {
				return self.indexOf(item) == pos;
			});
			return response;
		},
		openModal() {
			this.isOpen = true;
		}
	}
};
</script>

<style scoped></style>
